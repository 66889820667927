import request from '../utils/request'

const getData = (hotelID, roomId, location = null) => request.post(`/cleanReport/getH5Report?hotelCode=${hotelID}&roomNo=${roomId}${location ? `&userLongitude=${location.longitude}&userLatitude=${location.latitude}` : ''}`)
const getHotelInfo = (hotelID) => request.get(`/pub/hotel/baseInfoById?hotelCode=${hotelID}`)

// 获取酒店服务信息
// const getHotel = (siteId) => request.get(`http://47.110.230.84:9090/QRcodeInfo/value?siteId=${siteId}`)
const getHotel = (siteId) => request.get(`/QRcodeInfo/value?siteId=${siteId}`)
// `http://118.31.175.105:9090/QRcodeInfo/value?siteId=${siteId}`
// // 获取酒店图片
// const getImg = () => request.get('http://47.110.230.84:9090/site/info')

// 布草申领h5
const getCode = (params) => request.get('https://api.test.health.terabits.cn/applyCode/code', { params })
// 获取类别
const getTypeList = () => request.get('https://api.test.health.terabits.cn/base/type?name=apply')
// 布草申领
const putApply = (params) => request.post('https://api.test.health.terabits.cn/applyCode', params, {
  headers: {
    'Content-Type': 'application/json'
  }
})
// 获取申领相关信息
const getSite = (code) => request.get(`https://api.test.health.terabits.cn/applyCode/info?code=${code}`)
// 获取布草类型
const getLinen = (hotelId) => request.post(`https://api.test.health.terabits.cn/applyCode/list?hotelId=${hotelId}`)
// 获取当日布草申领记录
const getDayLinen = (code, applyDate) => request.get(`https://api.test.health.terabits.cn/applyCode/apply/record?code=${code}&applyDate=${applyDate}`)
// 
const getMaxLinen = (id, floorName) => request.get(`https://api.test.health.terabits.cn/applyCode/getApplyTextileType?hotelId=${id}&floorName=${floorName}`)
//获取楼层房型是否配置明细 
const getConfigLinenDetail = (id, floorName) => request.get(`https://api.test.health.terabits.cn/applyCode/getIsConfigLinenDetail?hotelId=${id}&floorName=${floorName}`)
const addAssess = (data) => request.post('/guestFeedBack/addFeedBack', data,
  {
    headers: {
      'Content-Type': 'application/json'
    }
  })

// w13 获取酒店楼层的物品列表
const getFloorGoodsList = (siteId, floorId, type) => request.get(`/goodInventory/getList?siteId=${siteId}&floorId=${floorId}&type=${type}`)
// w13 查询物品是否实发
const getApplyedGoodList = (params) => request.get('/goodApply/recordList', {
  params
})
// w13 提交物品申领
const postApplyGoods = (data) => request.post('/goodApply/apply', data, {
  headers: {
    'Content-Type': 'application/json'
  }
})
// w15 根据酒店code查找酒店信息
const getHotelInfoByHotelCode = (code) => request.get(`https://api.test.health.terabits.cn/applyCode/siteCode?siteId=${code}`)
//  w17 根据配置文件获取每周申领限制日期
const getApplyWeekJson = () => request.get('https://statics.terabits.cn/miniprogram/good_apply_time_config.json')
// w20 根据virtualFloorId获取本次申领类型（如中班，fo等)
const getGoodApplyConfigByVirtualFloor = (params) => request.get(`/goodApplyConfig/getVirtualFloor`, {
  params
})
export {
  getData,
  getHotel,
  getCode,
  getTypeList,
  putApply,
  getSite,
  getLinen,
  getHotelInfo,
  addAssess,
  getDayLinen,
  getMaxLinen,
  getConfigLinenDetail,
  getFloorGoodsList,
  getApplyedGoodList,
  postApplyGoods,
  getHotelInfoByHotelCode,
  getApplyWeekJson,
  getGoodApplyConfigByVirtualFloor
}
